// Footer.js
import React from 'react';
import '../footer/footer.css';
import { Container, Row, Col } from 'react-bootstrap';
import { FaPhoneAlt, FaHome, FaEnvelope, FaFacebook, FaTwitter, FaLinkedinIn } from 'react-icons/fa'
import { TbWorld } from 'react-icons/tb'

const Footer = () => {
  return (
    <footer>
      <div className="footer text-light p-4">
        <Container className="mt-4">
          <Row className='py-3'>
            <Col className="text-left" md={6}>
              <h5>ABOUT</h5>
              <p className='footer-about-p site-text-color'>Welcome to Open Road Travels Sri Lanka, founded by Harsha Pathirathna and Jayanjali Pathirathna, two passionate travelers dedicated to sharing the beauty, culture, and spirit of Sri Lanka with the world. Our mission is to create unforgettable journeys that immerse you in the island’s diverse landscapes, rich history, and vibrant culture.\nFrom serene beaches and lush tea plantations to ancient temples and thrilling wildlife adventures, we offer tailor-made tours designed to suit every traveler’s interests and preferences.\nAt Open Road Travels, we believe in delivering more than just a trip — we provide authentic experiences that connect you with the heart of Sri Lanka. Our commitment to sustainability, personalized service, and local expertise ensures that every journey is unique, comfortable, and memorable.</p>
              <ul className="list-unstyled">
                <li><FaHome className="contact-icon footer-contact-icons" /><span className='site-text-color'>Pradeep Madiha West, Matara Sri Lanka 81000</span></li>
                <li><a href="mailto:support@openroadtravels.com" className="d-flex align-items-center footer-contact-a site-text-color"><FaEnvelope className="contact-icon footer-contact-icons" />Email: support@openroadtravels.com</a></li>
                <li><a href="tel:+94704881559" className="d-flex align-items-center footer-contact-a site-text-color"><FaPhoneAlt className="contact-icon footer-contact-icons" /> Phone: +94 70 488 1559</a></li>
              </ul>
            </Col>
            <Col className="text-left" md={3}>
              <h5>CATEGORIES</h5>
              <ul className="list-unstyled">
                <li><a href="/faq" className="footer-link site-text-color">Home Appliances</a></li>
                <li><a href="/stay-safe" className="footer-link site-text-color">Computers</a></li>
                <li><a href="/contact" className="footer-link site-text-color">Fashion</a></li>
                <li><a href="/contact" className="footer-link site-text-color">Electronics</a></li>
                <li><a href="/contact" className="footer-link site-text-color">Mobile Phones</a></li>
              </ul>
            </Col>
            <Col className="text-left" md={3}>
              <h5>QUICK LINKS</h5>
              <ul className="list-unstyled">
                <li><a href="/about-us" className="footer-link site-text-color">Home</a></li>
                <li><a href="/privacy-policy" className="footer-link site-text-color">About Us</a></li>
                <li><a href="/privacy-policy" className="footer-link site-text-color">Contact Us</a></li>
              </ul>
            </Col>
          </Row>
          <hr />
          <div>
            <Row className="d-flex align-items-center">
              <Col md={6} className="text-left">
                <a href='https://www.linkedin.com/company/wizolve-technologies/'><p className="mb-0 site-text-color">Copyright © {new Date().getFullYear()} All Rights Reserved by CodeFlex.</p></a>
              </Col>
              <Col md={6} className="text-right d-flex justify-content-end align-items-center">
                <FaFacebook className="contact-icon footer-social-icons" />
                <FaTwitter className="contact-icon footer-social-icons" />
                <TbWorld className="contact-icon footer-social-icons" />
                <FaLinkedinIn className="contact-icon footer-social-icons" />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
