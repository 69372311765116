import React, { useState, useEffect } from 'react';
import '../home/home.css';
import { Carousel, Form, FormControl, Button, InputGroup, Row, Col, Card } from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import { FaSearch, FaRegStar } from 'react-icons/fa'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LocalIP from "../../LocalIP";
import axios from "axios"
import { useHistory } from 'react-router-dom';

const Home = () => {

    const [machinery, setMachinery] = useState([]);
    const [search, setSearch] = useState('');
    const [categorySearch, setCategorySearch] = useState('');
    const [areaSearch, setAreaSearch] = useState('');

    useEffect(() => onReload(), []);
    const history = useHistory();

    const onReload = () => {
        console.log("new")
        // const url = LocalIP + "machinery/";
        // axios.get(url).then((response) => {
        //     console.log(response["data"])
        //     setMachinery(response["data"])
        // });
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        autoplay: true,
        slidesToScroll: 1
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        var queryParams = ""
        if (search !== "") {
            if (categorySearch !== "") {
                if (areaSearch !== "") {
                    queryParams = new URLSearchParams({
                        search: search,
                        category: categorySearch,
                        area: areaSearch
                    }).toString();
                } else {
                    queryParams = new URLSearchParams({
                        search: search,
                        category: categorySearch
                    }).toString();
                }
            } else {
                if (areaSearch !== "") {
                    queryParams = new URLSearchParams({
                        search: search,
                        area: areaSearch
                    }).toString();
                } else {
                    queryParams = new URLSearchParams({
                        search: search
                    }).toString();
                }
            }
        } else {
            if (categorySearch !== "") {
                if (areaSearch !== "") {
                    queryParams = new URLSearchParams({
                        category: categorySearch,
                        area: areaSearch
                    }).toString();
                } else {
                    queryParams = new URLSearchParams({
                        category: categorySearch
                    }).toString();
                }
            } else {
                if (areaSearch !== "") {
                    queryParams = new URLSearchParams({
                        area: areaSearch
                    }).toString();
                } else {
                    history.push(`/all_ads`);
                }
            }
        }
        history.push(`/all_ads?${queryParams}`);
    };

    return (
        <div>
            <div id="home" className="web-view">
                <div className="hero-section" style={{ background: 'url(/assets/site_img21.jpg) center center/cover', height: '500px', color: 'white', textAlign: 'center', padding: '150px 0' }}>
                    <Container>
                        <h1>Discover Amazing Experiences</h1>
                        <p>Book unforgettable activities, tours, and more with confidence.</p>

                        {/* Search Bar */}
                        <Form inline className="justify-content-center mt-4">
                            <button className="btn btn-light" type="submit" variant="primary">BOOK NOW</button>
                        </Form>
                    </Container>
                </div>

                {/* Categories Section */}
                <Container className="my-5">
                    <h2>Explore by Category</h2>
                    <Row className="mt-4">
                        <Col md={3}>
                            <Card>
                                <Card.Img variant="top" src="/assets/site_img2.jpg" />
                                <Card.Body>
                                    <Card.Title>City Tours</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card>
                                <Card.Img variant="top" src="/assets/site_img3.jpg" />
                                <Card.Body>
                                    <Card.Title>Adventure</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card>
                                <Card.Img variant="top" src="/assets/site_img4.jpg" />
                                <Card.Body>
                                    <Card.Title>Beach</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card>
                                <Card.Img variant="top" src="/assets/site_img5.jpg" />
                                <Card.Body>
                                    <Card.Title>Cultural</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                {/* Featured Tours Section */}
                <Container className="my-5">
                    <h2>Featured Tours</h2>
                    <Row className="mt-4">
                        <Col md={4}>
                            <Card>
                                <Card.Img variant="top" src="/assets/site_img6.jpg" />
                                <Card.Body>
                                    <Card.Title>Tour Name</Card.Title>
                                    <Card.Text>Short description of the tour.</Card.Text>
                                    <Button variant="primary">View Details</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <Card.Img variant="top" src="/assets/site_img7.jpg" />
                                <Card.Body>
                                    <Card.Title>Tour Name</Card.Title>
                                    <Card.Text>Short description of the tour.</Card.Text>
                                    <Button variant="primary">View Details</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <Card.Img variant="top" src="/assets/site_img8.jpg" />
                                <Card.Body>
                                    <Card.Title>Tour Name</Card.Title>
                                    <Card.Text>Short description of the tour.</Card.Text>
                                    <Button variant="primary">View Details</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="home" className="mobile-view">
            </div>
        </div>
    );
}

export default Home;
